<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { ref } from "vue";
import { useMainStore } from "../store";
import type { Lead, QuizData, QuestionAnswer, UrlLogic } from '../types';
import sha256 from "crypto-js/sha256";


const store = useMainStore();
const quizData = store.quizData as QuizData;
const gate = store.gateData || {};
const lead: Lead = {} as Lead;
const form = ref(store.formLead);


function buildRedirectUrl(
  baseUrl: string,
  additionalParams?: Record<string, string | undefined>,
  hashParams?: Record<string, string | undefined>
): string {
  // Filter out undefined values from additionalParams
  const filteredParams: Record<string, string> = Object.entries(additionalParams || {})
    .filter(([, value]) => value !== undefined) // Remove undefined values
    .reduce((acc, [key, value]) => {
      acc[key] = value as string; // Ensure string type
      return acc;
    }, {} as Record<string, string>);

  // Filter out undefined values from hashParams
  const filteredHash: Record<string, string> = Object.entries(hashParams || {})
    .filter(([, value]) => value !== undefined) // Remove undefined values
    .reduce((acc, [key, value]) => {
      acc[key] = value as string; // Ensure string type
      return acc;
    }, {} as Record<string, string>);

  const queryParams = new URLSearchParams(filteredParams);

  let hashParamsString = '';
  if (Object.keys(filteredHash).length > 0) {
    hashParamsString = Object.entries(filteredHash)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    hashParamsString =`#${hashParamsString}`;
  }

  // Retornar a URL completa com os parâmetros
  return `${baseUrl}?${queryParams.toString()}${hashParamsString}`;
}

const setLead = () => {
  lead.quiz_id = quizData.quiz_id || '';
  lead.site_id = quizData.site_id || '';

  let direct_to: string | undefined = undefined;
  const leadAnswers = store.getLeadAnswers as unknown as QuestionAnswer[];
  const urlLogic: UrlLogic[] = store.quizUrlLogic || [];

  // Sort URLs by priority (lower number = higher priority)
  urlLogic.sort((a, b) => {
    if (a.priority === undefined && b.priority === undefined) return 0;
    if (a.priority === undefined) return 1;
    if (b.priority === undefined) return -1;
    return a.priority - b.priority;
  });

  // Find the first matching URL logic
  for (const url_logic of urlLogic) {
    const leadAnswerValues = leadAnswers.map((answer: QuestionAnswer) => answer.answer);
    const urlLogicAnswerValues = url_logic.answers.map(answer => answer.value);

    if (!url_logic.answers || url_logic.answers.length === 0 || leadAnswerValues.every((answer) => urlLogicAnswerValues.includes(answer))) {
      direct_to = url_logic.url;

      // Handle legacy url_variant with weight
      if (url_logic.url_variant && url_logic.variant_weight && Number(url_logic.variant_weight) > 0 && Number(url_logic.variant_weight) < 100) {
        if (Math.random() >= (1 - (parseInt(url_logic.variant_weight.toString()) / 100))) {
          direct_to = url_logic.url_variant;
        }
      // Handle url_variants array
      } else if (url_logic.url_variants) {
        // Handle legacy string array variants
        if (Array.isArray(url_logic.url_variants) && url_logic.url_variants.length > 0 && typeof url_logic.url_variants[0] === 'string') {
          const stringVariants = url_logic.url_variants.filter((v): v is string => typeof v === 'string');
          if (stringVariants.length > 0) {
            const index = Math.floor(Math.random() * stringVariants.length);
            direct_to = stringVariants[index];
            break;
          }
        }

        // Handle new structure with weights
        const weightedVariants = url_logic.url_variants.filter((v): v is { weight: number; url: string } => typeof v === 'object' && v !== null && 'weight' in v);
        const totalVariantWeight = weightedVariants.reduce((sum, variant) => sum + variant.weight, 0);
        const totalWeight = 100;
        
        // Check if weights exceed 100%
        if (totalVariantWeight > totalWeight) {
          console.error('Variant weights exceed 100%. Using default URL.');
          direct_to = url_logic.url;
          break;
        }

        // Handle empty variants array
        if (weightedVariants.length === 0) {
          direct_to = url_logic.url;
          break;
        }

        // Calculate cumulative weights
        let cumulativeWeight = 0;
        const variantWeights: number[] = [];
        weightedVariants.forEach(variant => {
          cumulativeWeight += variant.weight / totalWeight;
          variantWeights.push(cumulativeWeight);
        });

        // Add 100% weight for main URL, if no variant is found, defaults back to main url
        variantWeights.push(1);

        // Select URL based on random number
        const randomNum = Math.random();
        for (let i = 0; i < variantWeights.length; i++) {
          if (randomNum < variantWeights[i]) {
            direct_to = i < weightedVariants.length
              ? weightedVariants[i].url
              : url_logic.url;
            break;
          }
        }
      }

      if (direct_to) {
        break;
      }
    }
  }

  lead.direct_to = direct_to || quizData.quiz_default_url;
  lead.referrer = document.referrer;
  lead.origin = window.location.origin + window.location.pathname;

  // Cookies
  lead.fbp = store._getCookie('_fbp');
  lead.gid = store._getCookie('_gid');  // `gid` agora está definido em ExtendedLead

  // Query Parameters
  const queryParams = new URLSearchParams(window.location.search);
  lead.utm_source = queryParams.get('utm_source') || undefined;
  lead.utm_campaign = queryParams.get('utm_campaign') || undefined;
  lead.utm_medium = queryParams.get('utm_medium') || undefined;
  lead.utm_content = queryParams.get('utm_content') || undefined;
  lead.utm_term = queryParams.get('utm_term') || undefined;

  lead.fbclid = queryParams.get('fbclid') || undefined;
  lead.gclid = queryParams.get('gclid') || undefined;
  lead.sub_id = queryParams.get('sub_id') || undefined;

  lead.question_answers = leadAnswers.map(answer => ({ id: answer.id, answer: answer.answer })) as QuestionAnswer[];
};

const handleSubmit = async () => {
  setLead();
  lead.name = form.value.name;
  lead.email = form.value.email;
  lead.external_id = sha256(form.value.email).toString();
  lead.hashed_email = sha256(form.value.email).toString();
  lead.hashed_name = sha256(form.value.name).toString();
  lead.phone = form.value.phone;

  // !!! ATTENTION !!!
  // We are just sending the lead to the leadclump-quiz-api and not checking if the lead is valid (because it's faster)
  // We will check if the lead is valid in the leadclump-quiz-api and also on the landing page (to fire client side pixels)
  // !!! ATTENTION !!!
  
  // const site = domain;
  // const leadCheckResponse = await store.checkLead(lead.email, site);
  // const isLeadCheckValid = store.isLeadCheckValid(leadCheckResponse as { valid: boolean; safe: boolean });
  // if (!isLeadCheckValid) {
  //   if (!window.dataLayer) window.dataLayer = [];
  //   window.dataLayer.push({ event: 'invalidLead', e: lead.email, lead });
  // }

  await store.createLead(lead);

  const additionalParams = {
    utm_source: lead.utm_source,
    utm_medium: lead.utm_medium,
    utm_campaign: lead.utm_campaign,
    utm_content: lead.utm_content,
    utm_term: lead.utm_term,
    fbclid: lead.fbclid,
    gclid: lead.gclid,
    sub_id: lead.sub_id,
    // fbp: lead.fbp,
    // gid: lead.gid,
    // lead: isLeadCheckValid ? '1' : '0'
  } as Record<string, string>;

  // If the lead is NOT valid and safe, we send lead data to the landing page so it can be used to fire client side pixels
  // PS: if the lead is valid and safe, we already processed the lead and already fired the client side pixels, we are trying to mitigate the problem with repeated leads
  const hashParams = {} as Record<string, string>;
  if (!store.isLeadValidAndSafe) {
    // hashParams.l_n = lead.hashed_name;
    hashParams.l_e = lead.hashed_email;
  }

  const redirect_url = buildRedirectUrl(lead.direct_to, additionalParams, hashParams);

  store.resetQuiz();

  // Save email in cookie for 30 days
  // store._setCookie('leadclump_e', lead.email, 30);
  
  if (window.location !== window.parent.location) {
    window.top!.location.href = redirect_url;
  } else {
    window.location.href = redirect_url;
  }
};


</script>

<template>
  <div class="step step-5 step-form active">
    <h4>{{ gate.title || '' }}</h4>
    <h6 v-if="gate.sub_title">{{ gate.sub_title }}</h6>
    <div class="text-center" v-if="gate.image">
      <img :src="gate.image" :alt="gate.title" />
    </div>
    <p v-if="gate.description">{{ gate.description }}</p>
    <form @submit.prevent="handleSubmit" class="needs-validation novalidate">
      <input 
        type="text" 
        name="name" 
        :placeholder="gate.namePlaceholder" 
        :aria-label="gate.namePlaceholder" 
        required
        v-model="form.name" 
        :title="gate.nameError" 
        minlength="3"
      >
      <input 
        v-if="gate.usePhone" 
        type="tel" 
        name="phone" 
        placeholder="Digite seu telefone"
        aria-label="Digite seu telefone" 
        required 
        v-model="form.phone"
      >
      <input 
        v-if="gate.useEmail" 
        type="email" 
        name="email" 
        :placeholder="gate.emailPlaceholder"
        :aria-label="gate.emailPlaceholder" 
        required 
        v-model="form.email"
        pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
        :title="gate.emailError"
      >
      <button type="submit" :disabled="store.fetching" class="cta-button">
        <span v-if="!store.fetching">{{ gate.cta }}</span>
        <span v-else>{{ gate.loading || 'Carregando...' }}</span>
      </button>
    </form>
  </div>
</template>