import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createSentryPiniaPlugin } from "@sentry/vue";
//import 'mdb-vue-ui-kit/css/mdb.min.css';
import './pico.min.css'
import './style.css'
import App from './App.vue'
import router from './router'
import * as Sentry from "@sentry/vue";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

const pinia = createPinia();
pinia.use(createSentryPiniaPlugin());

const app = createApp(App);
Sentry.init({
  app,
  dsn: "https://d758a4e877440c1abe75e01731f43e87@o4508739336667136.ingest.us.sentry.io/4508739350298624",
  normalizeDepth: 10, // Or however deep you want your state context to be.
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    // /^https:\/\/(wgtk52k6dqdr5barbgeftvxcye0lqzlw|fhuezwgl4usr2pxjfxyhjlacgm0coxbh|vu4a4euzjxlsr4cecxfxhv5efq0ilgkk)\.lambda-url\.us-east-1\.on\.aws/,
    // /^https:\/\/cn4s36uzxh\.execute-api\.us-east-1\.amazonaws\.com\/api\/([^/]+)\/quiz\/([^/]+)/
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Adiciona todos os ícones solid e regular
library.add(fas)
library.add(far)

// Registra o componente FontAwesome globalmente
app.component('font-awesome-icon', FontAwesomeIcon)

app.use(pinia);
app.use(router);
// app.mount('#app');
router.isReady().then(() => {
    app.mount('#app')
})