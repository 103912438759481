import { createRouter, createWebHashHistory } from 'vue-router'
import Question from '../components/Question.vue'
import Gate from '../components/Gate.vue'

const router = createRouter({
  history: createWebHashHistory(), 
  routes: [
    {
      path: '/',
      name: 'question',
      component: Question
    },
    {
      path: '/gate',
      name: 'gate',
      component: Gate
    }
  ]
})

// router.beforeEach((to, from) => {
//   const store = useMainStore();
//   if (store.allQuestionsAnswered) {
//     if (to.name !== 'gate') {
//       to.name = 'gate';
//       return to;
//     }
//   } else {
//     if (to.name !== 'question') {
//       to.name = 'question';
//       return to;
//     };
//   }
// })

export default router