<template>
  <div class="app-wrapper">
    <div v-if="isLoading" class="loader">
      <span class="loader-text">Loading</span>
      <progress class="loader-progress" :value="progressLoaderPercentage" max="100"></progress>
    </div>
    <div v-else>
      <!-- Logo (sempre visível) -->
      <div class="logo-container">
        <img :src="logo" alt="Quiz Logo" class="logo" />
      </div>

      <!-- Barra de Progresso -->
      <div class="progress-container">
        <div class="progress-bar">
          <div class="progress" :style="{ width: progressBarPercentage + '%' }"></div>
        </div>
      </div>

      <div class="app-content">
        <!-- Conteúdo -->
        <RouterView :key="store.currentStep" />
      </div>

      <!-- Footer -->
      <footer class="footer">
        <div class="footer-icon">
          <svg 
            stroke="currentColor" 
            fill="#F26518"
            stroke-width="0" 
            viewBox="0 0 20 20" 
            height="50" 
            width="50" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path 
              fill-rule="evenodd" 
              d="M2.5 4A1.5 1.5 0 001 5.5V6h18v-.5A1.5 1.5 0 0017.5 4h-15zM19 8.5H1v6A1.5 1.5 0 002.5 16h15a1.5 1.5 0 001.5-1.5v-6zM3 13.25a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zm4.75-.75a.75.75 0 000 1.5h3.5a.75.75 0 000-1.5h-3.5z" 
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div class="company-info">
          <span class="company-name">{{ legal_name }}</span>
          <span v-if="legal_id" class="company-id">{{ legal_id }}</span>
        </div>
        <p class="disclaimer">{{ disclaimer }}</p>
        <div class="footer-links">
          <span>{{ year }}</span>
          <a :href="privacy_url" target="_blank" class="privacy">
            {{ privacy_text || "Privacy Policy" }}
          </a>
        </div>
      </footer>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useMainStore } from "./store";
const store = useMainStore();
store.checkRoutes();

const logo = computed(() => store.quizData?.logo || "");
const legal_name = computed(() => store.quizData?.legal_name || "");
const legal_id = computed(() => store.quizData?.legal_id || "");
const disclaimer = computed(() => store.quizData?.disclaimer || "");
const privacy_url = computed(() => store.quizData?.privacy_url || "");
const privacy_text = computed(() => store.quizData?.privacy_text || "Privacy Policy");

const progressBarPercentage = computed(() => store.progressBarPercentage);
// const currentStep = computed(() => store.currentStep);
const isLoading = computed(() => store.isLoading);
const progressLoaderPercentage = ref(15);
const intervalProgressLoader = setInterval(() => {
  if (progressLoaderPercentage.value < 100 && isLoading.value) {
    progressLoaderPercentage.value += (Math.random() * 7);
  } else {
    progressLoaderPercentage.value = 100;
    clearInterval(intervalProgressLoader);
  }
}, 200);

const year = new Date().getFullYear();

const themeColor = computed(() => store.quizData?.gate?.themeColor || '#F26518');

watch(themeColor, (newColor) => {
  document.documentElement.style.setProperty('--theme-color', newColor);
});

const config = {
  site_id: import.meta.env.VITE_SITE_ID,
  quiz_id: import.meta.env.VITE_QUIZ_ID
}
store.initializeQuizData(config.site_id, config.quiz_id);
</script>
