<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
  import { useMainStore } from "../store";
  import type { QuizData, Answer } from '../types';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

  const store = useMainStore();
  const question = store.currentQuestion;

  const handleAnswer = (answer: Answer) => {
    store.pushQuestionAnswer([question.id, answer[0]]);
    store.checkRoutes();
  };

  const isSelected = (answerId: string) => {
    return store.questionAnswers.some(([, answer]) => answer === answerId);
  };

  const quiz = store.quizData as QuizData;
</script>

<template>
  <div class="quiz-container">
    <!-- Cabeçalho do Quiz -->
    <div v-if="store.currentStep == 1">
      <h1 class="main-title">{{ quiz.title }}</h1>
      <p v-if="quiz.sub_title" class="subtitle">
        {{ quiz.sub_title }}
      </p>
    </div>

    <!-- Questão -->
    <div v-if="question" class="question-wrapper">
      <h2 class="question-title">{{ question.title }}</h2>

      <!-- Opções -->
      <div class="options-wrapper">
        <button
          v-for="answer in question.answers"
          :key="answer[0]"
          class="option-button"
          :class="{ 'selected': isSelected(answer[0]) }"
          @click="handleAnswer(answer)"
        >
          <div class="radio"></div>
          <div class="answer-content">
            <font-awesome-icon 
              v-if="answer[2]" 
              :icon="answer[2]" 
              class="answer-icon"
              :style="{ color: answer[3] || '#000000' }"
            />
            <span class="answer-text">{{ String(answer[1]) }}</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>