import { defineStore } from "pinia";
import router from "../router";
import type { QuizData, Answer, Lead } from "../types";

const API_URLS = {
  LEADCHECK: "https://wgtk52k6dqdr5barbgeftvxcye0lqzlw.lambda-url.us-east-1.on.aws/", // new leadclump-quiz-api function urls
  LEAD: "https://fhuezwgl4usr2pxjfxyhjlacgm0coxbh.lambda-url.us-east-1.on.aws/", // new leadclump-quiz-api function urls
  LEADCHECK_BY_HASHED_EMAIL: "https://vu4a4euzjxlsr4cecxfxhv5efq0ilgkk.lambda-url.us-east-1.on.aws/" // new leadclump-quiz-api function urls
};

const FETCH_QUIZ_DATA_URL = (site: string, quizId: string) =>
  `https://cn4s36uzxh.execute-api.us-east-1.amazonaws.com/api/${site}/quiz/${quizId}`;

async function loadQuizData(site: string, quizId: string): Promise<QuizData> {
  let quizDataLocal: QuizData;

  try {
    quizDataLocal = (await import("../quiz.json")).default as QuizData;
  } catch {
    quizDataLocal = {} as QuizData;
  }

  let quizData = quizDataLocal;
  const isDevelopment = import.meta.env.MODE === "development";

  if (isDevelopment && Object.keys(quizData).length > 0) {
    quizData = quizDataLocal;
  } else {
    try {
      console.log("fetching quizData from API...")
      quizData = await fetchQuizDataFromAPI(site, quizId);
    } catch {
      quizData = {} as QuizData;
    }
  }

  if (quizData.facebookpixels && !Array.isArray(quizData.facebookpixels)) {
    quizData.facebookpixels = [quizData.facebookpixels];
  }
  if (quizData.tiktokpixels && !Array.isArray(quizData.tiktokpixels)) {
    quizData.tiktokpixels = [quizData.tiktokpixels];
  }
  if (quizData.googleads && !Array.isArray(quizData.googleads)) {
    quizData.googleads = [quizData.googleads];
  }

  if (import.meta.env.MODE === "development") {
    quizData.url = "http://localhost:5173";
  }
  return quizData;
}

async function fetchQuizDataFromAPI(site: string, quizId: string): Promise<QuizData> {
  try {
    const response = await fetch(FETCH_QUIZ_DATA_URL(site, quizId));
    if (!response.ok) {
      console.error("Error loading quiz.json from API");
      return {} as QuizData;
    }
    return await response.json();
  } catch (error) {
    console.error("Connection error when trying to load quiz.json from API:", error);
    return {} as QuizData;
  }
}

export const useMainStore = defineStore({
  id: "main",
  state: () => ({
    scriptsLoaded: false,
    redirecting: false,
    fetching: false,
    questionAnswers: [] as Answer[],
    currentIndex: 0,
    formLead: { name: "", email: "", phone: "" },
    quizData: {} as QuizData,
    isLoading: true,
    isLeadValidAndSafe: false,
    currentStep: 1,
    totalSteps: 0,
  }),
  getters: {
    currentQuestion(state) {
      return state.quizData.questions?.[state.currentIndex] ?? null;
    },
    questionsLength(state) {
      return state.quizData.questions?.length || 0;
    },
    progressBarPercentage: (state) => {
      if (!state.totalSteps) return 0;
      return Math.round((state.currentStep / state.totalSteps) * 100);
    },
    isLastStep(): boolean {
      return this.currentIndex === this.questionsLength;
    },
    allQuestionsAnswered(state) {
      return (state.questionAnswers.length > 0 && state.questionAnswers.length === (state.quizData.questions?.length || 0));
    },
    quizQuestions(state) {
      return state.quizData.questions;
    },
    quizUrlLogic(state) {
      return state.quizData.url_logic;
    },
    gateData(state) {
      return state.quizData.gate;
    },
    getQuestionById(state) {
      return (question_id: string) => state.quizData.questions?.find((q) => q.id === question_id);
    },
    getLeadAnswers(state) {
      return state.questionAnswers.map((qa) => ({
        id: qa[0],
        answer: qa[1]
      }));
    },
    getLeadAnswerByQuestionId(state) {
      return (question_id: string) => state.questionAnswers.filter((qa) => qa[0] === question_id).map((qa) => qa[1]);
    },
  },
  actions: {
    async initializeQuizData(site: string, quizId: string) {
      this.isLoading = true;
      this.quizData = await loadQuizData(site, quizId);
      this.isLoading = false;
      this.checkRoutes();
      this.totalSteps = this.quizData?.questions?.length || 0;
    },
    pushQuestionAnswer(questionAnswer: Answer) {
      const isDuplicate = this.questionAnswers.some(
        ([qId, answer]) => qId === questionAnswer[0] && answer === questionAnswer[1]
      );

      if (!isDuplicate) {
        this.questionAnswers.push(questionAnswer);

        if (this.currentIndex < this.questionsLength - 1) {
          this.currentIndex += 1;
          this.currentStep = this.currentIndex + 1;
        }
      }
    },
    async apiRequest(url: string, method: string, data: unknown = null) {
      this.fetching = true;
      const options: RequestInit = {
        method,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: data ? JSON.stringify(data) : undefined,
      };

      try {
        const response = await fetch(url, options);
        if (response.ok)
          return await response.json();
        else
          throw response
      } catch (error) {
        console.error(error);
        return error;
      } finally {
        this.fetching = false;
      }
    },
    async checkLead(email: string, site: string): Promise<unknown> {
      this.fetching = true;
      try {
        const response = await fetch(API_URLS.LEADCHECK, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, site }),
        });

        if (!response.ok) {
          throw new Error(`Request error: ${response.status} - ${response.statusText}`);
        }

        const result = await response.json();
        console.log('Verification result:', result);
        return result;
      } catch (error) {
        console.error('Error checking lead:', error);
        throw error;
      } finally {
        this.fetching = false;
      }
    },
    isLeadCheckValid(response: { valid: boolean; safe: boolean }) {
      return response.valid && response.safe;
    },
    async createLead(lead: Lead) {
      const result = await this.apiRequest(API_URLS.LEAD, "POST", lead);
      return result;
    },
    async retrieveLeadcheckByHashedEmail() {
      const hashed_email = this._getCookie('leadclump_e');
      if (hashed_email) {
        const result = await this.apiRequest(API_URLS.LEADCHECK_BY_HASHED_EMAIL, "POST", {"hashed_email": hashed_email});
        return result;
      }
      return null;
    },
    resetQuiz() {
      this.redirecting = true;
      this.questionAnswers = [];
      this.currentIndex = 0;
    },
    checkRoutes() {
      if (this.allQuestionsAnswered) {
        this.currentStep = this.totalSteps + 1;
        router.push('/gate');
        return;
      }
      router.push({ name: 'question', query: router.currentRoute.value.query });
    },
    _getCookie(name: string): string {
      try {
        const value = document.cookie
          .split('; ')
          .find(row => row.startsWith(name + '='))
          ?.split('=')[1];
        return value ? decodeURIComponent(value) : '';
      } catch (e) {
        console.error(e);
        return '';
      }
    },
    _setCookie(name: string, value: string, days: number) {
      try {
        let expires = ""
        if (days) {
          const date = new Date()
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
          expires = "; expires=" + date.toUTCString()
        }
        document.cookie = name + "=" + (("" + value) || "") + expires + "; path=/"
      } catch (e) {
        console.error(e);
      }
    }
  },
});
